import { Customer } from '@/lib/sdk';
import { CartItem, ValidityPeriod } from '@/stores';
import { format as formatDate } from 'date-fns';

// encode string to sha-256 in the browser
export async function sha256(str: string): Promise<string> {
  // We transform the string into an arraybuffer.
  const buffer = new TextEncoder().encode(str);

  const hash = await crypto.subtle.digest('SHA-256', buffer);
  return Array.from(new Uint8Array(hash))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
}

export async function formatUserForGA(user: Customer) {
  try {
    const isLoggedIn = Boolean(user);

    const data = {
      l: isLoggedIn ? '1' : '0',
    };

    if (!isLoggedIn) {
      return data;
    }

    if (user?.thalesCustomerId) {
      data['i'] = await sha256(user.thalesCustomerId);
    }

    if (user?.phoneNumber || user?.mobileNumber) {
      const phoneNumber = (user.phoneNumber || user.mobileNumber).replace(
        /[\s()\+\-\.]/g,
        '',
      );

      data['psh'] = await sha256(phoneNumber);
      data['p'] = phoneNumber;
    }

    if (user?.email) {
      const email = user.email.toLowerCase().replace(/\s/g, '');

      data['esh'] = await sha256(email);
      data['e'] = email;
    }

    if (user?.firstName) {
      const firstName = user.firstName.toLowerCase().replace(/[\s\,\.]/g, '');

      data['fnsh'] = await sha256(firstName);
      data['fn'] = firstName;
    }

    if (user?.lastName) {
      const lastName = user.lastName.toLowerCase().replace(/[\s\,\.]/g, '');

      data['lnsh'] = await sha256(lastName);
      data['ln'] = lastName;
    }

    if (user?.title) {
      const title = user.title === 'M' ? 'm' : 'f';

      data['gsh'] = await sha256(title);
      data['g'] = title;
    }

    if (user?.billingAddress) {
      const city = user.billingAddress.city
        .toLowerCase()
        .replace(/[\s\,\.]/g, '');

      if (city) {
        data['ctsh'] = await sha256(city);
        data['ct'] = city;
      }

      data['pcsh'] = await sha256(user.billingAddress.postalCode);
      data['pc'] = user.billingAddress.postalCode;

      const country = user.billingAddress.country
        .toLowerCase()
        .replace(/[\s\,\.]/g, '');

      if (country) {
        data['cosh'] = await sha256(country);
        data['co'] = country;
      }
    }

    return data;
  } catch (error) {
    console.log(error);
    return { l: '0' };
  }
}

export function formatCategoryIdForGA(categoryId: number) {
  if (!categoryId) return '';

  //DIRTY HACK
  const globalStorage = localStorage.getItem('global');

  if (!globalStorage) return '';
  const categories = JSON.parse(globalStorage)?.categories;

  if (!categories) return '';

  const category = categories.find((category) => category.id === categoryId);

  if (!category) return '';

  const parsed = category.name.toLowerCase().replaceAll(/ /g, '_');

  return parsed;
}

export function formatCategoryNameForGA(categoryId: number) {
  if (!categoryId) return '';

  //DIRTY HACK
  const globalStorage = localStorage.getItem('global');

  if (!globalStorage) return '';
  const categories = JSON.parse(globalStorage)?.categories;

  if (!categories) return '';

  return categories.find((category) => category.id === categoryId)?.name || '';
}

export function formatDeliveryForGA(delivery: string) {
  switch (delivery) {
    case 'RELOAD':
      return 'Sur carte TBM';
    case 'TICKET':
      return 'Sur ticket';
    case 'MOBILE':
      return 'Sur mobile';
    default:
      return '';
  }
}

export function formatPriceForGA(price: number) {
  return (price / 100)
    .toLocaleString('en-US', { style: 'currency', currency: 'EUR' })
    .replaceAll('€', '');
}

const formatValidity = (validity: ValidityPeriod) => {
  try {
    const startDate = new Date(validity.startDate);
    const endDate = new Date(validity.endDate);

    return `${formatDate(startDate, 'dd-MM-yyyy')} - ${formatDate(
      endDate,
      'dd-MM-yyyy',
    )}`;
  } catch {
    return '';
  }
};

export function formatCartToGa(cart: CartItem[]) {
  const parseItem = (item: CartItem['item']) => ({
    item_id: item.product.id,
    item_name: item.product.title,
    item_variant: formatItemVariantForGA(item),
    price: formatPriceForGA(item.product.price),
    currency: 'EUR',
    quantity: item.quantity,
    item_brand: formatCategoryNameForGA(item.product.categoryId),
    item_category: formatCategoryNameForGA(item.product.categoryId),
  });

  return cart.reduce((acc, item) => {
    if (!item.item) return acc;

    const option = item.item.option;

    if (!option) return [...acc, parseItem(item.item)];

    const itemOtpion = { ...item.item, product: option };
    return [...acc, parseItem(item.item), parseItem(itemOtpion)];
  }, [] as CartItem[]);
}

export function formatItemVariantForGA(
  item: CartItem['item'],
  type = 'product' as 'product' | 'option',
) {
  const product = type === 'product' ? item.product : item.option;

  const profiles =
    item.profiles?.reduce((acc, profile) => {
      const foundProfile = product?.shopProfiles?.find(
        (shopProfile) => shopProfile.id === profile.profileId,
      );

      if (!foundProfile) return acc;

      return [...acc, profile];
    }, []) || [];

  const profilesString = profiles.join(', ');

  const dates = item.temporalValidity
    ? item.temporalValidity
        .map((validity) => formatValidity(validity))
        .join(', ')
    : '';

  const delivery = formatDeliveryForGA(item.delivery);

  const variantArray = [profilesString, dates, delivery].filter(Boolean);

  return variantArray.join('#');
}

type PageType =
  | 'home'
  | 'category'
  | 'product'
  | 'searchresults'
  | 'purchase'
  | 'account'
  | 'traveler-add'
  | 'traveler-card'
  | 'traveler-additional-info'
  | 'checkout-payment-selection'
  | 'checkout-security-deposite'
  | 'checkout-shipping-info'
  | 'checkout-payment-info'
  | 'checkout-payment-bankwire'
  | 'purchase'
  | 'other';

export function determinePageType(path: string): PageType {
  if (path === '/') return 'home';
  if (path.includes('category')) return 'category';
  if (path.includes('product')) return 'product';
  if (path.includes('search')) return 'searchresults';
  if (path.includes('confirm')) return 'purchase';
  if (path.includes('account')) return 'account';
  if (path.includes('associate/passenger')) return 'traveler-add';
  if (path.includes('associate/card')) return 'traveler-card';
  if (path.includes('associate/data')) return 'traveler-additional-info';
  if (path.includes('cart/methods')) return 'checkout-payment-selection';
  if (path.includes('cart/delivery')) return 'checkout-shipping-info';
  if (path.includes('cart/checkout')) return 'checkout-payment-info';
  if (path.includes('cart/confirm')) return 'purchase';

  return 'other';
}

const GALog = (...params: any[]) => {
  if (import.meta.env.VITE_DEBUG_MODE) {
    console.log('[DEBUG GA]', ...params);
  }
};

type GAEvent =
  | 'login'
  | 'logout'
  | 'sign_up'
  | 'signup_success'
  | 'view_item_list'
  | 'select_item'
  | 'view_item'
  | 'view_traveler_info'
  | 'view_traveler_card'
  | 'select_traveler_info'
  | 'view_traveler_additional_info'
  | 'add_to_cart'
  | 'view_cart'
  | 'remove_from_cart'
  | 'begin_checkout'
  | 'view_payment_selection'
  | 'add_payment_info'
  | 'view_bankwire_confirmation'
  | 'purchase'
  | 'view_payment_info'
  | 'add_shipping_info'
  | 'view_shipping_info'
  | 'view_security_deposite';

export async function dispatchGAEvent(
  event: GAEvent,
  pageType: PageType | null,
  user: Customer | undefined,
  data: Record<string, unknown> = {},
) {
  try {
    GALog('[DEBUG GA] Dispatching event', {
      event,
      pageType,
      user,
      data,
    });

    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      ecommerce: null,
      opt: null,
      ud: null,
    });
    const extraData = structuredClone(data);

    extraData['ud'] = await formatUserForGA(user);

    if (pageType) {
      extraData['pd'] = { t: pageType };
    }

    window['dataLayer'].push({ event, ...extraData });
  } catch (error) {
    GALog('[GA DEBUG]', { error });
  }
}

let firstRender = true;

export async function dispatchGAPage(
  pageType: PageType | null,
  user: Customer | undefined,
) {
  const event = firstRender ? 'tbm_pageview' : 'tbm_pageview_asc';

  firstRender = false;

  try {
    GALog('[DEBUG GA] Dispatching event', {
      event,
      pageType,
      user,
    });

    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      ecommerce: null,
      opt: null,
      ud: null,
    });

    const extraData = structuredClone({ ecommerce: null, opt: null });

    extraData['ud'] = await formatUserForGA(user);

    extraData['pd'] = { t: pageType || 'other' };

    window['dataLayer'].push({ event, ...extraData });
  } catch (error) {
    GALog('[GA DEBUG ERROR]', { error });
  }
}
