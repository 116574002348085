import './index.css';

import { render } from 'solid-js/web';
import { Router } from '@solidjs/router';
import * as Sentry from '@sentry/browser';
import { MetaProvider } from '@solidjs/meta';
import { BrowserTracing } from '@sentry/tracing';
import pkg from '../package.json';

import App from './app';
import { GlobalProvider } from './stores';
import { SdkProvider } from './stores/sdk';
import { AuthProvider } from './stores/auth';
import { TunnelProvider } from './stores/tunnel';

export const NUAPAY_SIGNATURE_MODE = import.meta.env.NUAPAY_SIGNATURE_MODE as
  | 'SMS'
  | 'EMAIL';

if (import.meta.env.VITE_SENTRY_DSN) {
  console.info('Sentry enabled with dsn: ', import.meta.env.VITE_SENTRY_DSN);

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],
	release: pkg.version,
	environment: import.meta.env.VITE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

/**
 * This is the bootstrapping function that starts the App
 */
function bootstrap() {
  // Prevent the app from running if there are missing environement variables
  if (!import.meta.env.VITE_API_URL) {
    throw new Error('No API_URL provided');
  }

  const today = new Date();
  today.setHours(4, 0, 0, 0);

  const lastCartDate = localStorage.getItem('lastCartDate');

  if (lastCartDate) {
    const lastCart = new Date(lastCartDate);
    lastCart.setHours(4, 0, 0, 0);

    const shouldClearCart = lastCart < today;

    if (shouldClearCart) {
      localStorage.removeItem('global');
    }
  }

  localStorage.setItem('lastCartDate', today.toString());

  return render(
    () => (
      <MetaProvider>
        <Router>
          <SdkProvider>
            <AuthProvider>
              <GlobalProvider>
                <TunnelProvider>
                  <App />
                </TunnelProvider>
              </GlobalProvider>
            </AuthProvider>
          </SdkProvider>
        </Router>
      </MetaProvider>
    ),
    document.getElementById('root'),
  );
}

bootstrap();
