import BAT3 from '@/assets/products/bat3.svg';
import PARKING from '@/assets/products/parking.svg';
import SCODI from '@/assets/products/scodi.svg';
import TER from '@/assets/products/ter.svg';
import TRAM from '@/assets/products/tram.svg';
import TRAMBUS from '@/assets/products/trambus.svg';
import V3 from '@/assets/products/v3.svg';
import { For, Show } from 'solid-js';

export const MOBILITY_ICONS = {
  BAT3: {
    icon: BAT3,
    name: 'BAT3',
  },
  V3: {
    name: 'LE VÉLO',
    icon: V3,
  },
  ABRISVELO: {
    name: 'ABRIS VÉLO',
    icon: V3,
  },
  TRAM: {
    name: 'TRAM',
    icon: TRAM,
  },
  TER: {
    name: 'TER',
    icon: TER,
  },
  'P+R': {
    name: 'P+R',
    icon: PARKING,
  },
  TRAMBUS: {
    name: 'TRAM/BUS',
    icon: TRAMBUS,
  },
  SCODI: {
    name: 'SCODI',
    icon: SCODI,
  },
};

interface Props {
  mobilities: string[];
}
export function ProductPictos({ mobilities }: Props) {
  const pictos = () => {
    if (!mobilities?.length) {
      return [];
    }

    const pictos = [];

    if (mobilities.find((m) => ['BUS', 'TRAM', 'TRAMBUS'].includes(m))) {
      pictos.push(MOBILITY_ICONS['TRAMBUS']);
    }

    if (mobilities.includes('BAT3')) {
      pictos.push(MOBILITY_ICONS['BAT3']);
    }

    if (mobilities.includes('P+R')) {
      pictos.push(MOBILITY_ICONS['P+R']);
    }

    if (mobilities.includes('V3')) {
      pictos.push(MOBILITY_ICONS['V3']);
    }

    if (mobilities.includes('ABRISVELO')) {
      pictos.push(MOBILITY_ICONS['ABRISVELO']);
    }

    if (mobilities.includes('TER')) {
      pictos.push(MOBILITY_ICONS['TER']);
    }

    if (mobilities.includes('SCODI')) {
      pictos.push(MOBILITY_ICONS['SCODI']);
    }

    return pictos;
  };

  return (
    <ul class="mt-2 flex flex-wrap  lg:order-2 lg:w-full ">
      <For each={pictos()}>
        {(picto) => (
          <Show when={picto} keyed>
            <li class="mx-2 mt-2 flex flex-col items-center">
              <img src={picto.icon} class="h-10" alt="" />

              <span class="mt-2 inline-block text-xs font-semibold uppercase">
                {picto.name}
              </span>
            </li>
          </Show>
        )}
      </For>
    </ul>
  );
}
