import SearchDuplicate from '../zendesk/searchDuplicate';
import CardPlaceholder from '@/assets/card.png';
import Button from '@/components/button';
import {Confidenciality} from '@/components/confidenciality';
import Input from '@/components/input';
import Modal from '@/components/modal';
import Select from '@/components/select';
import {Tooltip} from '@/components/tooltip';
import {Child, CustomerAddress, ThalesUser} from '@/lib/sdk';
import {getInitials} from '@/pages/account';
import {extractFormValues} from '@/pages/associate/passenger';
import {useAuth} from '@/stores/auth';
import {useSdk} from '@/stores/sdk';
import {formatDate} from '@/utils/formatDate';
import {dispatchGAEvent} from '@/utils/googleAnalytics';
import {pick} from '@/utils/pick';
import {format, formatISO9075, sub} from 'date-fns';
import {createEffect, createSignal, JSX, Match, ParentProps, Show, Switch,} from 'solid-js';
import {createStore} from 'solid-js/store';
import {A} from "@solidjs/router";
import Address from "@/components/address";

const COUNTRIES = {
  FR: 'France',
  ES: 'Espagne',
  EN: 'Angleterre',
  IT: 'Italie',
  OTHER: 'Autre',
};

const errors = {
  NO_CLIENT_FOUND: 'Aucun client ne correspond à ces informations.',
  ALREADY_PAIRED: `Ce voyageur est déjà associé au compte.`,
  MANY_CLIENTS_FOUND:
	  'Plusieurs résultats trouvés. Merci de contacter le support.',
  PAYER_CODE_NOT_FOUND(search: Record<string, string>) {
	return `Le numéro client ${search.id} n’appartient pas au compte client payeur ${search.lastName} ${search.firstName}. Veuillez contacter le Service Client pour qu’un agent puisse vous communiquer votre code client.`;
  },
  USER_ALREADY_EXISTS(search: Record<string, string>) {
	return `Un voyageur avec les informations suivantes existe déjà : ${
		search.lastName
	} ${search.firstName}, né(e) le ${
		search?.birthDate ? formatDate(search?.birthDate) : '-'
	}.\n\nVous ne retrouvez plus votre code client ? Contactez le service client.`;
  },
  DEFAULT: 'Une erreur est survenue. Veuillez réessayer ultérieurement.',
};

/**
 * Function that takes an object representing the Thales Customer Address
 * (could be billing, referred as address1 in this page, or shipping, referred as address 2 in this page),
 * and format it into a string on two lines.
 */
function formatAddress(address: CustomerAddress) {
  let formattedAddress = `${address.streetNumber} ${address.streetName}`;

  if (address.deliveryPoint) {
	formattedAddress += `, ${address.deliveryPoint}`;
  }

  if (address.deliveryPointSupplement) {
	formattedAddress += `, ${address.deliveryPointSupplement}`;
  }

  formattedAddress += `\n`;
  formattedAddress += `${address.postalCode} ${address.city}, ${
	  COUNTRIES[address.country]
  }`;

  return formattedAddress;
}

function MixedSearchForm() {
  const today = new Date();
  const fiveYearsAgo = sub(today, {years: 5});
  const maxDate = format(fiveYearsAgo, 'yyyy-MM-dd');
  const [auth] = useAuth();

  return (
	  <fieldset class="mt-8 space-y-4">
		<legend class="sr-only">Informations du client</legend>

		<Input
			name="lastName"
			id="lastName"
			label="Nom"
			value={auth.user.lastName}
			required
			placeholder="Nom de famille"
		/>
		<Input
			name="firstName"
			id="firstName"
			label="Prénom"
			value={auth.user.firstName}
			required
			placeholder="Prénom"
		/>
		<Input
			name="birthDate"
			id="birthDate"
			label="Date de naissance"
			max={maxDate}
			type="date"
			required
			placeholder="Date de naissance"
		/>

		<div>
		  <input id="terms" name="terms" type="checkbox" required/>

		  <label for="terms" class="ml-4 cursor-pointer text-sm">
          <span>
            Je certifie que l’identité du payeur renseignée ci-dessus correspond
            à celle présente sur le relevé d’identité bancaire qui va être
            utilisé pour le paiement de la commande. *
          </span>
		  </label>
		</div>
	  </fieldset>
  );
}

function AlreadyClientForm() {
  const today = new Date();
  const fiveYearsAgo = sub(today, {years: 5});
  const maxDate = format(fiveYearsAgo, 'yyyy-MM-dd');

  return (
	  <fieldset class="mt-8 space-y-4">
		<legend class="sr-only">Informations du client</legend>
		<Input
			name="lastName"
			label="Nom"
			required
		/>
		<Input
			name="firstName"
			label="Prénom"
			required
		/>
		<Input
			name="birthDate"
			id="birthDate"
			label="Date de naissance"
			max={maxDate}
			type="date"
			required
			placeholder="Date de naissance"
		/>
		<Input
			name="email"
			id="email"
			label="Adresse e-mail"
			type="mail"
			placeholder="Adresse e-mail"
		/>
	  </fieldset>
  );
}

function AlreadyClientButDontKnowHisCustomerIdForm() {
  const today = new Date();
  const fiveYearsAgo = sub(today, {years: 5});
  const maxDate = format(fiveYearsAgo, 'yyyy-MM-dd');

  return (
	  <fieldset class="mt-8 space-y-4">
		<legend class="sr-only">Informations du client</legend>

		<Input
			name="lastName"
			id="lastName"
			label="Nom"
			placeholder="Nom de famille"
		/>
		<Input
			name="firstName"
			id="firstName"
			label="Prénom"
			placeholder="Prénom"
		/>
		<Input
			name="birthDate"
			id="birthDate"
			label="Date de naissance"
			max={maxDate}
			type="date"
			required
			placeholder="Date de naissance"
		/>
	  </fieldset>
  );
}

function NotClientForm(
	props: ParentProps<{
	  searchValues: Record<string, string>;
	  isMainAccount?: boolean;
	  mixedSearch?: boolean;
	  passengerInfos?: Child | undefined;
	}>,
) {
  const [auth] = useAuth();
  const today = new Date();
  const fiveYearsAgo = sub(today, {years: 5});
  const maxDate = format(fiveYearsAgo, 'yyyy-MM-dd');

  return (
	  <>
		<Show when={props.isMainAccount}>
		  <p class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300">
			<Show when={auth.user.certifiedIdentity} fallback={
			  <>
				Si vos données d’identité sont inexactes, rendez-vous dans votre <A href="/account" class="font-bold">espace
				personnel </A> pour les modifier.
			  </>
			}>
			  Votre identité est certifiée, vous ne pouvez plus modifier vos nom, prénom et date de naissance
			</Show>
		  </p>
		</Show>
		<fieldset class="mt-4 space-y-4">
		  <legend class="sr-only">Informations personnelles</legend>

		  <Select
			  required
			  label="Civilité"
			  name="title"
			  id="title"
			  disabled={Boolean(props.passengerInfos)}
			  options={[
				{value: 'M', label: 'Monsieur'},
				{value: 'MME', label: 'Madame'},

			  ]}
			  value={props.searchValues?.title ?? (props.passengerInfos ? props.passengerInfos.title : '')}
			  placeholder="Civilité"
		  />

		  {/*Add hidden input to submit equivalent of select value when is disabled*/}

		  <Input type="hidden" name="hiddenTitle" disabled={!Boolean(props.passengerInfos)}
				 value={props.searchValues?.title ?? (props.passengerInfos ? props.passengerInfos.title : '')}/>

		  <Input
			  label="Nom"
			  name="lastName"
			  id="lastName"
			  readOnly={Boolean(props.passengerInfos)}
			  value={props.searchValues?.lastName ?? (props.passengerInfos ? props.passengerInfos.lastName : '')}
			  required
			  placeholder="Nom de famille"
		  />
		  <Input
			  label="Prénom"
			  name="firstName"
			  id="firstName"
			  required
			  readOnly={Boolean(props.passengerInfos)}
			  placeholder="Prénom"
			  value={props.searchValues?.firstName ?? (props.passengerInfos ? props.passengerInfos.firstName : '')}
		  />
		  <Input
			  label="Date de naissance"
			  type="date"
			  required
			  placeholder="Date de naissance"
			  max={maxDate}
			  readOnly={Boolean(props.passengerInfos)}
			  id="birthDate"
			  name="birthDate"
			  onInvalid={(event) => {
				const input = event.target as HTMLInputElement;
				input.setCustomValidity(
					'Le réseau TBM est gratuit pour les moins de 5 ans.',
				);
			  }}
			  value={props.searchValues?.birthDate ?? (props.passengerInfos ? formatISO9075(new Date(props.passengerInfos.birthDate), {representation: 'date'}) : '')}
		  />
		  <Input
			  label="Adresse e-mail"
			  required
			  placeholder="Adresse e-mail"
			  id="email"
			  name="email"
			  type="email"
			  value={props.passengerInfos ? props.passengerInfos.email : ''}
			  readOnly={Boolean(props.passengerInfos)}
		  />
		</fieldset>

		<fieldset class="mt-8 space-y-4">
		  <legend class="text-sm font-semibold text-gray-400">
			Numéro de téléphone
		  </legend>

		  <Input
			  label="Mobile"
			  id="mobileNumber"
			  name="mobileNumber"
			  required={props.isMainAccount}
			  placeholder="Téléphone mobile"
			  type="tel"
			  pattern="^(\+|0)[1-9][0-9]{1,14}$"
			  value={props.passengerInfos ? props.passengerInfos.mobileNumber : ''}
		  />
		  <Input
			  label="Fixe"
			  id="phoneNumber"
			  name="phoneNumber"
			  type="tel"
			  pattern="^(\+|0)[1-9][0-9]{1,14}$"
			  placeholder="Téléphone fixe"
			  value={props.passengerInfos ? props.passengerInfos.phoneNumber : ''}
		  />
		</fieldset>
		<Address defaultAddress={props.isMainAccount ? auth.getUser.billingAddress : false} countries={COUNTRIES}/>
	  </>
  );
}

function PassengerForm<T extends Record<string, string>>(
	props: ParentProps<{
	  handleSubmit: (
		  formValues: T,
		  hasCustomerId: boolean,
		  isMainAccountPairing: boolean,
	  ) => void;
	  error?: string;
	  loading?: boolean;
	  showCreationSection?: boolean;
	  onlyCreationSection?: boolean;
	  onlyParent?: boolean;
	  title?: string;
	  subtext?: string;
	  mixedSearch?: boolean;
	  alreadyClient?: boolean;
	  showCustomerCode?: boolean;
	  previousSearch?: Record<string, string>;
	  searchValues?: Record<string, string>;
	  cleanError: () => void;
	  onFormCreationDisplay?: (isCreationFormShown: boolean) => void;
	  passenger?: string;
	  passengerInfos?: Child | undefined;
	}>,
) {
  const [auth] = useAuth();
  const [alreadyClient, setAlreadyClient] = createSignal(props.alreadyClient);
  const [hasCustomerId, setHasCustomerId] = createSignal(true);

  createEffect(() => {
	setAlreadyClient(props.alreadyClient);
  });

  createEffect(() => {
	if (props.onFormCreationDisplay) {
	  props.onFormCreationDisplay(!alreadyClient());
	}
  });

  const mainAccount = auth.user.rcuId === props.passenger;

  const [isMainAccountPairing, setIsMainAccountPairing] =
	  createSignal(mainAccount);

  const [showZendeskSupport, setShowZendeskSupport] = createSignal(false);

  function handleSubmit(e: Event) {
	e.preventDefault();

	const form = e.currentTarget as HTMLFormElement;

	const formValues = extractFormValues(form) as T;

	if (!formValues.title) {
	  formValues.title = formValues.hiddenTitle;
	}

	props.handleSubmit(formValues, alreadyClient(), isMainAccountPairing());
  }

  return (
	  <Show
		  when={!showZendeskSupport()}
		  fallback={
			<Show when={showZendeskSupport()}>
			  <SearchDuplicate
				  onCancel={() => {
					setShowZendeskSupport(false);
					props.cleanError();
				  }}
				  values={{
					...props.previousSearch,
					...props.searchValues,
					email: auth.user.email,
					mobileNumber: auth.user.mobileNumber,
				  }}
			  />
			</Show>
		  }
	  >
		<form onSubmit={handleSubmit} class="mt-6">
		  <Show when={props.title}>
			<h2 class="text-center text-lg font-semibold">{props.title}</h2>
		  </Show>
		  <Show when={props.subtext}>
			<p class="mt-4 whitespace-pre-wrap text-center text-sm">
			  {props.subtext}
			</p>
		  </Show>
		  <Show when={props.showCreationSection && !props.onlyCreationSection}>
			<fieldset>
			  {/* This should be a `<legend>` but it seems like safari doesn't work with flex `legends` :( */}
			  <p class="flex items-center justify-center space-x-2 text-center text-sm font-semibold">
				<span>Le voyageur est-il client TBM ?</span>
				<Tooltip
					placement="bottom"
					title="Le voyageur est-il client TBM ?"
					icon={
					  <svg class="h-5 " viewBox="0 0 30 30">
						<path
							d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,1.5A13.5,13.5,0,1,0,28.5,15,13.49,13.49,0,0,0,15,1.5ZM14.56,19a1.52,1.52,0,0,1,0,3,1.52,1.52,0,0,1,0-3Zm.25-11.51c2.53,0,4.21,1.4,4.21,3.26s-1.68,3.2-2.21,3.61a2.48,2.48,0,0,0-1.11,2.47c0,.37-.2.81-1.16.81-.81,0-1.09-.3-1.09-1.33a3.64,3.64,0,0,1,1.32-3c.65-.55,1.76-1.16,1.76-2.23,0-.91-.79-1.34-1.78-1.34-2,0-1.58,1.52-2.64,1.52a1.14,1.14,0,0,1-1.19-1.13C10.92,9.05,12.15,7.47,14.81,7.47Z"/>
					  </svg>
					}
				>
				  <main class="relative grid w-full max-w-xs gap-4 text-sm">
					<p>
					  Vous êtes client TBM si vous disposez d'un code client TBM.
					</p>
					<p>
					  Votre code client est le numéro à 7 chiffres qui se trouve
					  sur votre carte TBM, dans l'encadré code client.
					</p>
					<img
						src={CardPlaceholder}
						alt="Représentation d'une carte TBM avec le code client en bas à droite"
						class="block h-auto w-full"
					/>
					<p>
					  Si vous n'avez pas de carte mais que vous avez déjà payé un
					  abonnement par prélèvement automatique pour un membre de
					  votre famille, votre code client est présent sur votre
					  échéancier dans les informations de client payeur.
					</p>
				  </main>
				</Tooltip>
			  </p>

			  <div class="mt-4 flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
				<div class="flex-1">
				  <button
					  type="button"
					  class="block w-full rounded-lg border py-4 text-center text-sm focus:ring"
					  classList={{
						'bg-primary border-primary text-white': alreadyClient(),
						'border-gray-300 text-gray-800': !alreadyClient(),
					  }}
					  onClick={() => {
						setAlreadyClient(true);
						props.cleanError();
					  }}
				  >
					Oui
				  </button>
				</div>

				<div class="flex-1">
				  <button
					  type="button"
					  class="block w-full rounded-lg border py-4 text-center text-sm focus:ring"
					  classList={{
						'bg-primary border-primary text-white': !alreadyClient(),
						'border-gray-300 text-gray-800': alreadyClient(),
					  }}
					  onClick={() => {
						setAlreadyClient(false)
						props.cleanError();
					  }}
				  >
					Non
				  </button>
				</div>
			  </div>
			</fieldset>
		  </Show>
		  <Show
			  when={alreadyClient()}
			  fallback={
				<NotClientForm
					searchValues={props.searchValues}
					isMainAccount={isMainAccountPairing()}
					passengerInfos={props.passengerInfos}
				/>
			  }
		  >
			<Show when={!props.mixedSearch}>
			  <Show
				  when={hasCustomerId()}
				  fallback={<AlreadyClientButDontKnowHisCustomerIdForm/>}
			  >
				<AlreadyClientForm/>
			  </Show>
			</Show>

			<Show when={props.mixedSearch}>
			  <Show when={props.showCustomerCode} fallback={<MixedSearchForm/>}>
				<fieldset class="mt-8 space-y-4">
				  <legend class="sr-only">Informations du client</legend>

				  <Input
					  name="id"
					  id="id"
					  label="Code client"
					  placeholder="Code client"
					  required
				  />

				  <input
					  name="birthDate"
					  id="birthDate"
					  value={props?.searchValues?.birthDate}
					  type="hidden"
				  />
				</fieldset>
			  </Show>
			</Show>
		  </Show>
		  <Show when={props.error}>
			<p class="mt-4 whitespace-pre-wrap rounded-lg border border-red-700 bg-red-50 p-4 text-sm font-semibold text-red-700 text-center">
			  {() => {
				const error = errors[props.error];
				if (!error) {
				  return props.error;
				}

				if (typeof error === 'function') {
				  return error({
					...props.previousSearch,
					...props.searchValues,
				  });
				}

				return error;
			  }}
			</p>
		  </Show>
		  <p class="mt-8 text-center text-xs font-semibold text-gray-400">
			*Champs obligatoires
		  </p>

		  <Switch
			  fallback={
				<Show
					when={alreadyClient()}
					fallback={
					  <Button
						  loading={props.loading}
						  variant="secondary"
						  type="submit"
						  class="mx-auto mt-4"
					  >
						Valider la création
					  </Button>
					}
				>
				  <Button
					  loading={props.loading}
					  variant="secondary"
					  type="submit"
					  class="mx-auto mt-4"
				  >
					Rechercher
				  </Button>
				</Show>
			  }
		  >
			<Match when={props.error === 'PAYER_CODE_NOT_FOUND'}>
			  <Button
				  variant="secondary"
				  type="button"
				  class="mx-auto mt-4"
				  onClick={[setShowZendeskSupport, true]}
			  >
				Contacter le service client
			  </Button>

			  <button
				  type="button"
				  onClick={props.cleanError}
				  class="mx-auto mt-4 block font-semibold underline"
			  >
				Renseignez un nouveau code
			  </button>
			</Match>

			<Match when={props.error === 'USER_ALREADY_EXISTS'}>
			  <Button
				  variant="secondary"
				  type="button"
				  class="mx-auto mt-4"
				  onClick={[setShowZendeskSupport, true]}
			  >
				Contacter le service client
			  </Button>
			</Match>

			<Match when={props.error === 'MANY_CLIENTS_FOUND'}>
			  <Button
				  variant="secondary"
				  type="button"
				  class="mx-auto mt-4"
				  onClick={[setShowZendeskSupport, true]}
			  >
				Contacter le service client
			  </Button>

			  <button
				  type="button"
				  onClick={props.cleanError}
				  class="mx-auto mt-4 block font-semibold underline"
			  >
				Procéder à une nouvelle recherche
			  </button>
			</Match>
		  </Switch>

		  <Confidenciality/>
		</form>
	  </Show>
  );
}

function SearchResultModal(
	props: ParentProps<{
	  open: boolean;
	  loading: boolean;
	  isMainAccount: boolean;
	  onClose: JSX.EventHandlerUnion<
		  HTMLDivElement | HTMLButtonElement,
		  MouseEvent
	  >;
	  onSubmit: (formData: any) => void;
	  thalesClient: ThalesUser;
	}>,
) {
  const [auth] = useAuth();

  // Because this information is not reactive (yet), I wrapped the whole component in a Show.
  const initialContactInformation: {
	email: string;
	mobileNumber: string;
	phoneNumber?: string;
  } = {
	email: props.isMainAccount ? auth.user.email : props.thalesClient.email,
	mobileNumber: props.thalesClient.mobileNumber,
  };

  if (props.thalesClient.phoneNumber) {
	initialContactInformation.phoneNumber = props.thalesClient.phoneNumber;
  }

  const [activeForms, setActiveForms] = createStore({
	contact: false,
	address1: false,
  });

  const [contactForm, setContactForm] = createSignal(initialContactInformation);
  const [prefillAdress, setPrefillAdress] = createSignal(true);
  const [address1Form, setAddress1Form] = createSignal<null | CustomerAddress>(
	  props.thalesClient.billingAddress,
  );

  createEffect(() => {
	if (!props.thalesClient?.mobileNumber && props.isMainAccount) {
	  setActiveForms('contact', true);
	}
  });

  const fullName = () =>
	  `${props.thalesClient.firstName} ${props.thalesClient.lastName}`;
  const initials = () => getInitials(fullName());
  const isFemale = () => props.thalesClient.title !== 'M';
  const birthDate = () => formatDate(props.thalesClient.birthDate);

  return (
	  <Modal title="Recherche" open={props.open} onClose={props.onClose}>
		<p class="text-center text-sm">
		  Voici les informations de votre dossier client.
		</p>

		<p class="text-center text-sm">
		  Vous avez la possibilité de les mettre à jour, en respectant les champs
		  obligatoires indiqués par un (*).
		</p>

		<div class="mt-10 flex items-center space-x-6">
        <span class="flex h-14 w-14 items-center justify-center rounded-full bg-primary text-white">
          {initials()}
        </span>

		  <div>
			<p class="font-semibold">{fullName()}</p>
			<p>
			  {isFemale() ? `Née` : `Né`} le {birthDate()}
			</p>
			<p>Code client : {props.thalesClient.id}</p>
		  </div>
		</div>

		<div class="mt-6">
		  <fieldset>
			<legend class="text-sm font-semibold text-gray-400">
			  Mes informations de contact
			</legend>

			<Show
				when={activeForms.contact}
				fallback={
				  <>
					<div class="mt-2 flex space-x-6 text-sm">
					  <span>{contactForm().email}</span>
					  <span>{contactForm().mobileNumber}</span>
					  <span>{contactForm().phoneNumber}</span>
					</div>

					<Button
						variant="primary"
						onClick={() => setActiveForms({contact: true})}
						class="mt-4"
					>
					  Modifier
					</Button>
				  </>
				}
			>
			  <form
				  onSubmit={(event) => {
					event.preventDefault();

					const values = extractFormValues(event.currentTarget, [
					  'email',
					  'mobileNumber',
					  'phoneNumber',
					]);

					setContactForm(values);
					setActiveForms({contact: false});
				  }}
				  class="mt-2 grid grid-cols-2 gap-4"
			  >
				<Input
					label="Adresse email"
					name="email"
					type="mail"
					class="col-span-2 col-start-1 row-start-1 lg:col-span-1"
					required
					readOnly={props.isMainAccount}
					value={contactForm().email}
				/>
				<Input
					label="Numéro de mobile"
					required={props.isMainAccount}
					name="mobileNumber"
					type="tel"
					inputMode="tel"
					class="col-start-1 row-start-2"
					pattern="^(\+|0)[1-9][0-9]{1,14}$"
					value={contactForm().mobileNumber}
				/>
				<Input
					label="Téléphone fixe"
					name="phoneNumber"
					type="tel"
					inputMode="tel"
					class="col-start-2 row-start-2"
					pattern="^(\+|0)[1-9][0-9]{1,14}$"
					value={contactForm().phoneNumber}
				/>

				<div class="col-start-1 row-start-3 self-start">
				  <Button variant="primary" type="submit" class="mt-4 ">
					Valider
				  </Button>

				  <button
					  type="button"
					  class="mt-4 text-primary underline"
					  onClick={() => setActiveForms({contact: false})}
				  >
					Annuler
				  </button>
				</div>
			  </form>
			</Show>
		  </fieldset>

		  <fieldset class="mt-8">
			<legend class="text-sm font-semibold text-gray-400">Adresse 1</legend>

			<Show
				when={activeForms.address1}
				fallback={
				  <>
					<div class="mt-2 flex space-x-6 text-sm">
					  <p class="whitespace-pre leading-loose">
						{formatAddress(address1Form())}
					  </p>
					</div>

					<Button
						onClick={() => setActiveForms({address1: true})}
						variant="primary"
						class="mt-4"
					>
					  Modifier
					</Button>
				  </>
				}
			>
			  <form
				  onSubmit={(event) => {
					event.preventDefault();

					const values = extractFormValues(event.currentTarget, [
					  'streetNumber',
					  'streetName',
					  'deliveryPoint',
					  'deliveryPointSupplement',
					  'postalBox',
					  'postalCode',
					  'city',
					  'country',
					]);
					setAddress1Form(values as any);
					setActiveForms({address1: false});
					setPrefillAdress(true);
				  }}
				  class="mt-2 flex grid-cols-3 flex-col gap-4 lg:grid"
			  >

				<Address defaultAddress={prefillAdress() ? address1Form() : false}/>

				<div class="col-start-1 row-start-4 self-start">
				  <Button variant="primary" type="submit" class="mt-4 ">
					Valider
				  </Button>

				  <button
					  type="button"
					  class="mt-4 text-primary underline"
					  onClick={() => setActiveForms({address1: false})}
				  >
					Annuler
				  </button>
				</div>
			  </form>
			</Show>

			<button
				onClick={() => {
				  setPrefillAdress(false);
				  setActiveForms({address1: true});
				}}
				type="button"
				class="mt-4 text-red-600 underline"
			>
			  Supprimer l'adresse
			</button>
		  </fieldset>

		  {/* TODO: Address 2 */}

		  <p class="mt-8 text-center text-xs font-semibold text-gray-400">
			*Champs obligatoires
		  </p>

		  <Button
			  variant="secondary"
			  loading={props.loading}
			  // If at least one form is open then we can't validate the form
			  disabled={Object.values(activeForms).some(Boolean)}
			  onClick={() => {
				// TODO: Validate the form
				// paco : 30/08/1999 - 605741
				const contact = Object.entries(contactForm()).reduce(
					(acc, [key, value]) => {
					  if (Boolean(value)) {
						return {...acc, [key]: value};
					  }

					  return {...acc, [key]: null};
					},
					{},
				);

				const form: Record<string, any> = {...contact};
				const oldAddress = JSON.stringify(
					props.thalesClient.billingAddress,
				);
				const newAddress = JSON.stringify(address1Form());

				const isSameAddress = oldAddress === newAddress;
				if (!isSameAddress) {
				  form.address1 = address1Form();
				}

				props.onSubmit(form);
			  }}
			  class="mx-auto mt-4"
		  >
			Confirmer les informations
		  </Button>

		  <button
			  type="button"
			  onClick={props.onClose}
			  class="mx-auto mt-4 block text-center text-sm font-semibold underline"
		  >
			Effectuer une nouvelle recherche
		  </button>
		</div>
	  </Modal>
  );
}

export default function PairingForm(
	props: ParentProps<{
	  onSubmit?: (user: string, isMainAccount: boolean, rcuAssociation?) => void;
	  showCreationForm?: boolean;
	  onlyCreationForm?: boolean;
	  onlyParent?: boolean;
	  title?: string;
	  subtext?: string;
	  cykleoContext?: boolean
	  cykleoOption?: boolean
	  mixedSeach?: boolean;
	  passenger?: string;
	  onFormCreationDisplay?: (isCreationFormShown: boolean) => void;
	  onClientCodeDisplay?: (
		  isCustomerFoundAfterSearch: boolean,
		  formValues: Record<string, unknown>,
	  ) => void;
	  passengerInfo?: Child;
	}>,
) {
  const [sdk] = useSdk();
  const [auth, authActions] = useAuth();

  const [isLoading, setIsLoading] = createSignal(false);
  const [isSearching, setIsSearching] = createSignal(false);
  const [isMainAccount, setIsMainAccount] = createSignal(true);
  const [showSearchResult, setShowSearchResult] = createSignal(false);
  const [thalesClient, setThalesClient] = createSignal<ThalesUser>(null);
  const [preventCreation, setPreventCreation] = createSignal(false);
  const [alreadyClient, setAlreadyClient] = createSignal(!props.onlyCreationForm);
  const [showCustomerCode, setShowCustomerCode] = createSignal(false);

  const [error, setError] = createSignal('');

  const [previousSearch, setPreviousSearch] =
	  createSignal<Record<string, string>>();
  const [searchValues, setSearchValues] =
	  createSignal<Record<string, string>>();

  async function handleSubmit(
	  formValues: Record<string, string>,
	  alreadyClient: boolean,
	  mainAccount: boolean,
  ) {
	try {
	  setIsSearching(true);
	  setIsMainAccount(mainAccount);
	  setError('');
	  setPreventCreation(false);

	  const searchValuesFromForm = pick(
		  formValues,
		  'id',
		  'birthDate',
		  'customerId',
		  'firstName',
		  'lastName',

		  'thalesCustomerId',
		  'email',
		  'phoneNumber',
		  'mobileNumber',
	  ) as Record<string, string>;

	  // HACK: that's a hack to keep in memory the latest triptique search
	  if (searchValuesFromForm.firstName && searchValuesFromForm.lastName) {
		setPreviousSearch(searchValuesFromForm);
	  }

	  setSearchValues(formValues);

	  // We transform the date, not sure if needed anymore
	  if (searchValuesFromForm.birthDate) {
		const birthDate = new Date(searchValuesFromForm.birthDate as string);
		// TODO: We probably don't need to do that anymore
		searchValuesFromForm.birthDate = alreadyClient
			? birthDate.toISOString()
			: formatISO9075(birthDate, {representation: 'date'});
	  }

	  // If we already have thales client then we search for it
	  if (alreadyClient) {
		const rcuAssociation = await sdk.customer.association(searchValuesFromForm);

		if (rcuAssociation.found) {
		  props.onSubmit(null, mainAccount, rcuAssociation, searchValuesFromForm)
		  return
		}

		return setError('NO_CLIENT_FOUND');

		/*try {
		  const thalesClient = (await sdk.thales.search(
			  searchValuesFromForm,
		  )) as ThalesUser;

		  if (props.mixedSeach && typeof thalesClient === 'boolean') {
			props?.onClientCodeDisplay(thalesClient, searchValuesFromForm);

			// If we are in mixedSearch and we found a customer
			if (thalesClient) {
			  return setShowCustomerCode(true);
			}

			// If we are in mixedSearch, we want to switch to the creation form
			// instead of displaying an error
			if (!thalesClient) {
			  return setAlreadyClient(false);
			}
		  }

		  if (!thalesClient) {
			return setError('NO_CLIENT_FOUND');
		  }

		  const children = await sdk.customer.children(true);
		  const childrenIds = children.map((child) => child.thalesCustomerId);
		  childrenIds.unshift(auth.user.thalesCustomerId);

		  if (childrenIds.includes(thalesClient.id)) {
			return setError('ALREADY_PAIRED');
		  }

		  setThalesClient(thalesClient);

		  return setShowSearchResult(true);
		} catch (error) {
		  setPreventCreation(true);

		  if (error?.cause?._data?.message === 'THALES_CUSTOMER_NOT_FOUND') {
			if (props.mixedSeach) {
			  return setError('PAYER_CODE_NOT_FOUND');
			} else {
			  return setError('NO_CLIENT_FOUND');
			}
		  }

		  return setError('DEFAULT');
		}*/
	  }

	  // If we reach this code, that means creation of a user
	  const creationUserValue = pick(
		  formValues,
		  'title',
		  'firstName',
		  'lastName',
		  'email',
		  'birthDate',
		  'phoneNumber',
		  'mobileNumber',
	  );

	  const addressValue = pick(
		  formValues,
		  'streetNumber',
		  'streetName',
		  'deliveryPoint',
		  'deliveryPointSupplement',
		  'postalBox',
		  'postalCode',
		  'city',
		  'country',
	  );

	  let userId: string;
	  if (props.onlyCreationForm && props.showCreationForm && props.onlyParent) {

		if (props.cykleoContext || (props.cykleoOption && auth.user.thalesCustomerId)) {
		  await sdk.customer.updateAddress(undefined, addressValue);
		  await sdk.customer.update(creationUserValue);

		} else {
		  await sdk.svd.create({
			...creationUserValue,
			// @ts-ignore
			billingAddress: addressValue,
			rcuId: props.passenger
		  });
		}


		const customer = await authActions.getCurrentCustomer()
		userId = customer.rcuId
	  } else {
		// otherwise we basically try to create a new customer
		const thalesUser = await sdk.thales.create({
		  ...creationUserValue,
		  // @ts-ignore
		  billingAddress: addressValue,
		});

		userId = thalesUser.rcuId;
	  }

	  // Refill the cache
	  await sdk.customer.children(false);

	  props?.onSubmit(userId, isMainAccount());
	} catch (error) {
	  if (error?.cause?._data?.message === 'USER_ALREADY_EXISTS') {
		setError('USER_ALREADY_EXISTS');
	  } else {
		setError('DEFAULT');
	  }

	  console.error(error);
	} finally {
	  setIsSearching(false);
	}
  }

  async function handleSearchResultForm(formData) {
	setIsLoading(true);

	const {address1, ...personalForm} = formData;

	const userId = thalesClient().id;

	if (isMainAccount()) {
	  const user = await sdk.customer.update({
		thalesCustomerId: userId,
		...personalForm,
	  });

	  if (address1) {
		await sdk.customer.updateAddress(userId, address1);
	  }

	  authActions.set({user});

	  dispatchGAEvent('select_traveler_info', null, auth.user, {
		opt: {
		  who: 'vous',
		  isc: 'oui',
		},
	  });
	} else {
	  await sdk.customer.addChild({thalesCustomerId: userId});

	  await sdk.customer.updateChild(userId, personalForm);

	  if (address1) {
		await sdk.customer.updateAddress(userId, address1);
	  }

	  dispatchGAEvent('select_traveler_info', null, auth.user, {
		opt: {
		  who: 'un autre voyageur',
		  isc: 'oui',
		},
	  });
	}

	// Refill the cache
	await sdk.customer.children(false);

	props.onSubmit?.(userId, isMainAccount());

	setIsLoading(false);
	setIsSearching(false);
	setShowSearchResult(false);
  }

  return (
	  <>
		<PassengerForm
			error={error()}
			title={props.title}
			loading={isSearching()}
			subtext={props.subtext}
			handleSubmit={handleSubmit}
			onlyParent={props.onlyParent}
			mixedSearch={props.mixedSeach}
			alreadyClient={alreadyClient()}
			searchValues={searchValues()}
			previousSearch={previousSearch()}
			cleanError={() => setError('')}
			onFormCreationDisplay={props.onFormCreationDisplay}
			showCreationSection={!preventCreation() && props.showCreationForm}
			onlyCreationSection={props.onlyCreationForm && props.showCreationForm}
			showCustomerCode={showCustomerCode()}
			passenger={props.passenger}
			passengerInfos={props.passengerInfo}
		/>

		{/* Wrapping this in a show for now although that's not how it should be working... */}
		{/* The reason being is explain in the component itself */}
		<Show when={showSearchResult()}>
		  <SearchResultModal
			  open={true}
			  loading={isLoading()}
			  thalesClient={thalesClient()}
			  isMainAccount={isMainAccount()}
			  onClose={() => setShowSearchResult(false)}
			  onSubmit={handleSearchResultForm}
		  />
		</Show>
	  </>
  );
}
