import {CatalogListResponse, ClosureStatus, CustomerAddress, Product, Voucher,} from '@/lib/sdk';
import {createUniqueId} from '@/utils/createUniqueId';
import {defineStore} from '@/utils/defineStore';

export interface AddItemPayload {
  profiles?: ItemProfile[];
  quantity: number;
  product: Product;
  delivery: 'RELOAD' | 'TICKET' | string | null;
  temporalValidity?: ValidityPeriod[];
  options?: Product[];
}

export interface ItemProfile {
  profileId?: string | number;
  proofDocuments: { documentType: string | number; streamId: string }[];
}

export interface ValidityPeriod {
  startDate: string;
  endDate: string;
}

export interface CartItem {
  id: string;
  passenger?: string;
  media?: string | { idCard: string; idPhoto: string };
  organizationCode?: string;
  item: {
	product: Product;
	options?: Product[];
	quantity: number;
	delivery?: string;
	profiles?: ItemProfile[];
	temporalValidity?: ValidityPeriod[];
  };
}

type Category = {
  id: number;
  name: string;
  smartContextTag?: string;
  parent?: Category;
};

export const [GlobalProvider, useGlobalStore] = defineStore({
  state: () => ({
	menuOpen: false,
	catalog: [] as CatalogListResponse[],
	categories: [] as Category[],
	delivery: null as CustomerAddress,
	agency: null as string,
	method: 'CASH' as 'CASH' | 'SCHEDULED',
	lastFourDigits: '',
	cart: [] as CartItem[],
	termsAccepted: false,
	cykleoTermsAccepted: false,
	cykleoWarrantyDepositInfoRead: false,
	closureStatus: null as ClosureStatus,
	voucher: null as Voucher & { code: string },
  }),

  watchers: () => ({
	menuOpen(isOpen) {
	  const method = isOpen ? 'add' : 'remove';
	  document.body.classList[method]('overflow-hidden');
	},
  }),

  actions: (state, setState) => {
	return {
	  products(): Product[] {
		if (!state.catalog?.length) {
		  return [];
		}

		return state.catalog.reduce((catalog, {products, children}) => {
		  if (children.length) {
			const products = children.flatMap(({products}) => products);
			catalog.push(...products);
		  }

		  catalog.push(...products);

		  return catalog;
		}, []);
	  },
	  shouldShowMethodSelection(): boolean {
		return state.cart.every((cartItem) => {
		  const hasSchedulePayment = cartItem.item.product.variants.some(
			  (variant) => {
				if (variant.paymentMode !== 'SCHEDULED') return false;

				if (variant.salesEnd && new Date(variant.salesEnd) < new Date()) {
				  return false;
				}

				if (
					variant.salesStart &&
					new Date(variant.salesStart) > new Date()
				) {
				  return false;
				}
				return true;
			  },
		  );

		  return hasSchedulePayment;
		});
	  },
	  shouldShowAddressesSelection(): boolean {
		return state.cart.some(
			(item) =>
				item.item.delivery !== 'RELOAD' || typeof item.media !== 'string',
		);
	  },
	  shouldShowDataPage(): boolean {
		//DIRTY : check if there's 'LE VELO' option

		return state.cart.some(({item}) => {
		  if (!item.options?.length) return false;

		  return item.options.some(
			  (option) =>
				  option.variants.some(variant => variant.providerId === 'CYKLEO'),
		  );
		});
	  },
	  cartNumber(): number {
		return state.cart.length;
	  },
	  hasIsolatedProductInCart(): boolean {
		return state.cart.some(
			({item}) =>
				item.product.isYearlySubscription || item.product.isScodi,
		);
	  },
	  totalCart() {
		if (!state.cart) return 0;

		return state.cart.reduce((acc, {item}) => {
		  const quantity = item?.temporalValidity?.length ?? item.quantity;
		  const nextPrice = acc + item.product.price * quantity;

		  const sumOptionPrice =
			  item.options?.reduce((acc, option) => {
				return acc + option.price * quantity;
			  }, 0) || 0;

		  return nextPrice + sumOptionPrice;
		}, 0);
	  },
	  clearStore() {
		setState({
		  lastFourDigits: '',
		  method: null,
		  cart: [],
		  delivery: null,
		  termsAccepted: false,
		  agency: null,
		  voucher: null,
		});
	  },
	  getCartItemById(id: string) {
		return state.cart.find((item) => item.id === id);
	  },
	  // TODO: Handle option probably here
	  addToCart(
		  itemToAdd: AddItemPayload,
		  passenger?: string,
		  media?: any,
		  organizationCode?: string,
	  ) {
		const existingProduct = state.cart.findIndex((cartItem) => {
		  const isSamePassenger = cartItem.passenger === passenger;
		  const isSameProduct =
			  cartItem.item.product.id === itemToAdd.product.id;
		  const isSameSupport = cartItem.item.delivery === itemToAdd.delivery;

		  return isSameProduct && isSameSupport && isSamePassenger;
		});

		if (existingProduct !== -1) {
		  setState(
			  'cart',
			  [existingProduct],
			  'item',
			  'quantity',
			  (quantity) => quantity + itemToAdd.quantity,
		  );

		  return state.cart[existingProduct];
		}

		const newItem = {
		  id: createUniqueId(),
		  item: itemToAdd,
		  passenger,
		};

		if (organizationCode) {
		  // @ts-ignore
		  newItem.organizationCode = organizationCode;
		}

		if (media) {
		  // @ts-ignore
		  newItem.media = media;
		}

		setState('cart', (cart) => [...cart, newItem]);

		return newItem;
	  },
	  removeFromCart(id: string) {
		setState('cart', (cart) => cart.filter((item) => item.id !== id));
	  },
	  toggleMenu() {
		setState('menuOpen', (isOpen) => !isOpen);
	  },
	  updateCartQuantity(id: string, quantity: number) {
		setState(
			'cart',
			(item) => item.id === id,
			'item',
			'quantity',
			quantity,
		);
	  },
	  findProduct(productId: string | number): Product | null {
		const products: Product[] = this.products();
		return products?.find(
			(product) => String(product.id) === String(productId),
		);
	  },
	};
  },
  storage: {
	key: 'global',
	properties: [
	  'cart',
	  'delivery',
	  'method',
	  'lastFourDigits',
	  'voucher',
	  'agency',
	  'categories',
	  'cykleoWarrantyDepositInfoRead'
	],
  },
});
