import homeBg from '@/assets/bg-home.png?meta=height;width;src';
import categoryAnnuel from '@/assets/categories/annuel.svg';
import categoryJournee from '@/assets/categories/journee.svg';
import categorySingle from '@/assets/categories/single.svg';
// Assets stuff
import faq1 from '@/assets/faq/faq.svg';
import faq2 from '@/assets/faq/quick.svg';
import Button from '@/components/button';
import Carousel from '@/components/carousel';
import CarouselSlide from '@/components/carousel/slide';
import Checkbox from '@/components/checkbox';
import Input from '@/components/input';
import Modal from '@/components/modal';
import { ProductPictos } from '@/components/products';
import { SmartContextContainer, TAGS } from '@/components/smart-context';
import { Product, SubscriptionExpires } from '@/lib/sdk';
import { useGlobalStore } from '@/stores';
import { useAuth } from '@/stores/auth';
import { useSdk } from '@/stores/sdk';
import { formatDate } from '@/utils/formatDate';
import { formatPrice } from '@/utils/formatPrice';
import {
  dispatchGAEvent,
  dispatchGAPage,
  formatCategoryNameForGA,
  formatPriceForGA,
} from '@/utils/googleAnalytics';
import { Title } from '@solidjs/meta';
import { A, useNavigate } from '@solidjs/router';
import { add, format, lastDayOfMonth, set, sub } from 'date-fns';
import { fr as LocaleFR } from 'date-fns/esm/locale';
import sd from 'snarkdown';
import { Icon } from 'solid-heroicons';
import { chevronRight, search } from 'solid-heroicons/outline';
import {
  For,
  ParentProps,
  Show,
  createMemo,
  createResource,
  createSignal,
  onMount,
} from 'solid-js';
import { createStore } from 'solid-js/store';

function FancyCategory(
  props: ParentProps<{
    image: string;
    title: string;
    description: string;
    class?: string;
    href?: string;
  }>,
) {
  return (
    <article class={`${props?.class} rounded-lg bg-white`}>
      <A href={props.href || ''} class="flex items-center space-x-4 px-4 py-6">
        <img
          src={props.image}
          alt={`Visuel catégorie ${props.title}`}
          width="96"
          height="85"
          class="block h-auto w-24"
        />

        <div class="flex flex-1 flex-col space-y-2">
          <h3 class="text-lg font-semibold">{props.title}</h3>
          <p class="whitespace-pre-line text-xs">{props.description}</p>
        </div>

        <div>
          <Icon path={chevronRight} class="block h-8 w-8 text-blue-400" />
        </div>
      </A>
    </article>
  );
}

export function FancyProduct(props: ParentProps<Product>) {
  const [auth] = useAuth();

  const onClickDispatchGAEvent = () => {
    const dataGA = {
      item_list_id: 'home',
      item_list_name: 'Home',
      items: [
        {
          item_id: props.id,
          item_name: props.title,
          item_brand: formatCategoryNameForGA(props.categoryId),
          item_list_id: 'home',
          item_list_name: 'Home',
          price: formatPriceForGA(props.price),
          currency: 'EUR',
          quantity: '1',
          index: 1,
          item_category: formatCategoryNameForGA(props.categoryId),
        },
      ],
    };

    dispatchGAEvent('select_item', null, auth.user, dataGA);
  };

  return (
    <article class="flex flex-col items-center justify-center rounded-lg border p-4 lg:h-full lg:flex-1 lg:px-6 lg:py-9">
      <Show when={props.image}>
        <img
          src={props.image}
          alt={`Visuel produit ${props.title}`}
          width="96"
          height="96"
          class="block h-24 w-24"
        />
      </Show>

      <h3 class="mt-4 text-center text-lg font-semibold">{props.title}</h3>

      <p class="mt-4 w-80 whitespace-pre-wrap text-center text-sm">
        {props.shortText}
      </p>

      <hr class="my-4 w-24" />

      <Show
        when={props.isYearlySubscription}
        fallback={
          <span class="text-xl font-semibold">{formatPrice(props.price)}</span>
        }
      >
        <span class="text-xl font-semibold">
          {formatPrice(props.monthlyPrice)} /mois
        </span>
        <span class="text-gray-400">ou {formatPrice(props.price)} /an</span>
      </Show>

      <Button
        href={`/products/${props.id}`}
        variant="primary"
        class="mt-8"
        onClick={onClickDispatchGAEvent}
      >
        En savoir plus
      </Button>
    </article>
  );
}

export function PrettyTitle(
  props: ParentProps<{ color: 'purple' | 'green' | 'pink' | 'blue' }>,
) {
  const colors = () => {
    switch (props.color) {
      case 'pink':
        return 'from-pink-500';
      case 'green':
        return 'from-green-500';
      case 'purple':
        return 'from-purple-500';
      case 'blue':
      default:
        return 'from-blue-500';
    }
  };

  return (
    <h2 class="flex items-center justify-center space-x-4 text-center text-xl font-semibold text-gray-700 lg:text-3xl">
      <span
        class={`${colors()} relative block h-2 w-10 bg-gradient-to-l after:absolute after:right-0 after:top-1/2 after:h-3 after:w-3 after:-translate-y-1/2 after:translate-x-1/2 after:rounded-full after:bg-black lg:w-16`}
      ></span>

      <span>{props.children}</span>

      <span
        class={`${colors()} relative block h-2 w-10 rotate-180 bg-gradient-to-l after:absolute after:right-0 after:top-1/2 after:h-3 after:w-3 after:-translate-y-1/2 after:translate-x-1/2 after:rounded-full after:bg-black lg:w-16`}
      ></span>
    </h2>
  );
}

function ReSubscription({
  subscription,
}: ParentProps<{ subscription: SubscriptionExpires }>) {
  const [auth] = useAuth();
  const [_, globalActions] = useGlobalStore();
  const go = useNavigate();
  const [sdk] = useSdk();

  const [children] = createResource(() => sdk.customer.children(true));

  const [contract] = subscription.contracts;

  const product = () =>
    globalActions.products().find((product) => {
      return product.variants.some(
        (variant) => variant.thalesCode === contract.productCode,
      );
    });

  const isMainAccount = () => {
    return subscription.recipientId === auth.user.thalesCustomerId;
  };
  const customer = () => {
    return [auth.user, ...(children() || [])].find(
      (child) => child?.thalesCustomerId === subscription.recipientId,
    );
  };

  // That's used for yearly subscriptions
  const firstAvailableDate = () => {
    return add(new Date(contract.validityEndDate), { days: 1 });
  };

  const [showConfirmationModale, setShowConfirmationModale] =
    createSignal(false);

  const [form, setForm] = createStore({
    startDates: contract.periodicity === 'YEARLY' ? [firstAvailableDate()] : [],
  });

  function handleSubmit(event: SubmitEvent) {
    event.preventDefault();

    globalActions.set('method', 'CASH');

    if (contract.periodicity === 'YEARLY') {
      globalActions.addToCart(
        {
          product: product(),
          quantity: 1,
          delivery: 'RELOAD',
          temporalValidity: form.startDates.map((startDate) => ({
            startDate: startDate.toISOString(),
            endDate: add(sub(startDate, { days: 1 }), {
              years: 1,
            }).toISOString(),
          })),
        },
        subscription.recipientId,
        contract.mediaId,
      );

      return go('/cart/checkout');
    }

    if (contract.periodicity === 'MONTHLY') {
      globalActions.addToCart(
        {
          product: product(),
          quantity: 1,
          delivery: 'RELOAD',
          temporalValidity: form.startDates.map((startDate) => ({
            startDate: set(startDate, { date: 1 }).toISOString().split('T')[0],
            endDate: lastDayOfMonth(startDate).toISOString().split('T')[0],
          })),
        },
        subscription.recipientId,
        contract.mediaId,
      );

      return go('/cart/checkout');
    }
  }

  const availableStartDates = () => {
    return [
      new Date(),
      add(new Date(), { months: 1 }),
      add(new Date(), { months: 2 }),
    ];
  };

  function onChangeStartDates(event: Event) {
    const input = event.target as HTMLInputElement;
    const isChecked = input.checked;
    const value = input.value;

    if (isChecked) {
      setForm('startDates', (startDates) => {
        return [...startDates, new Date(value)].sort((a, b) => {
          return a.getTime() - b.getTime();
        });
      });
    } else {
      setForm('startDates', (startDates) => {
        return startDates.filter((startDate) => {
          return startDate.toISOString() !== new Date(value).toISOString();
        });
      });
    }
  }

  return (
    <section class="px-4 py-12">
      <div class="mx-auto flex max-w-7xl flex-col">
        <PrettyTitle color="green">Se réabonner en un clic</PrettyTitle>

        <div class="relative mt-8 grid gap-6">
          <p class="text-center">
            <span>
              {isMainAccount() ? 'Votre abonnement' : "L'abonnement"}{' '}
            </span>
            <span>
              {contract.periodicity === 'YEARLY' ? 'annuel' : 'mensuel'}{' '}
            </span>
            <span>
              {isMainAccount() && customer()
                ? ''
                : `de ${customer()?.firstName}`}{' '}
            </span>
            <span>
              arrive à expiration le {formatDate(contract.validityEndDate)}.{' '}
            </span>
            <span>N'attendez plus pour le renouveler !</span>
          </p>

          <form
            onSubmit={(event) => {
              event.preventDefault();
              setShowConfirmationModale(true);
            }}
            class="mt-4 flex flex-col rounded-lg border border-gray-300 bg-white px-4 py-4 lg:grid lg:px-10 lg:py-10 "
            classList={{
              'lg:grid-cols-[15%,35%,20%,30%]': Boolean(product().image),
              'lg:grid-cols-[0%,50%,20%,30%]': !Boolean(product().image),
            }}
          >
            <Show when={product().image}>
              <img
                src={product().image}
                alt=""
                class="mx-auto h-32 lg:col-start-1 lg:row-span-4 lg:self-center"
              />
            </Show>

            <Show when={product().mobilities}>
              <ProductPictos mobilities={product().mobilities} />
            </Show>

            <p
              innerHTML={sd(product().shortText)}
              class="prose prose-sm mt-6 lg:col-start-2 lg:row-span-4 lg:row-start-1 lg:mt-0 lg:self-center lg:px-4"
            />

            <hr class="mx-auto my-8 w-20 border-gray-300 lg:hidden" />

            <Show
              when={contract.periodicity === 'YEARLY'}
              fallback={
                <p class="mx-auto text-2xl font-bold lg:col-start-4 lg:row-start-1">
                  {formatPrice(product().price)}
                </p>
              }
            >
              <div class="mx-auto lg:col-start-4 lg:row-start-1">
                <p class="text-xl font-bold">
                  {formatPrice(product().monthlyPrice)} /mois
                </p>
                <p class="text-center text-sm text-gray-400">
                  ou {formatPrice(product().price)} /an
                </p>
              </div>
            </Show>

            <Show when={contract.periodicity === 'YEARLY'}>
              <Input
                class="mt-4"
                label="Date de début d'abonnement"
                type="date"
                name="startDate"
                required
                value={format(form.startDates[0], 'yyyy-MM-dd')}
                onChange={(e) => {
                  setForm('startDates', [new Date(e.currentTarget.value)]);
                }}
                min={format(firstAvailableDate(), 'yyyy-MM-dd')}
                max={format(
                  add(firstAvailableDate(), { years: 1 }),
                  'yyyy-MM-dd',
                )}
              />
            </Show>

            <Show when={contract.periodicity === 'MONTHLY'}>
              <fieldset class="mt-4">
                <legend class="font-semibold">Mois de recharge</legend>

                <For each={availableStartDates()}>
                  {(startDate) => (
                    <Checkbox
                      name="date"
                      class="text-sm uppercase"
                      // We want to disable the months that are already covered by a contract
                      disabled={subscription.contracts.some((contract) => {
                        const contractMonth = new Date(
                          contract.validityStartDate,
                        ).getMonth();
                        const startDateMonth = startDate.getMonth();

                        return contractMonth === startDateMonth;
                      })}
                      onChange={onChangeStartDates}
                      value={format(startDate, 'yyyy-MM-dd')}
                      label={format(startDate, 'LLLL yyyy', {
                        locale: LocaleFR,
                      })}
                    />
                  )}
                </For>
              </fieldset>
            </Show>

            <Button
              type="submit"
              variant="secondary"
              class="mx-auto mt-8 w-full lg:col-start-4 lg:mx-auto lg:w-auto"
            >
              Se réabonner
            </Button>
          </form>
        </div>
      </div>

      <Modal
        open={showConfirmationModale()}
        onClose={[setShowConfirmationModale, false]}
        title="Confirmation de réabonnement"
      >
        <div class="grid gap-4 text-center">
          <p>
            Vous êtes sur le point de renouveler l’abonnement de{' '}
            {customer()?.firstName} {customer()?.lastName}.
          </p>

          <Show
            when={contract.periodicity === 'YEARLY'}
            fallback={
              <>
                <p>
                  Si vous confirmez cette action, votre abonnement sera
                  renouvelé pour les mois :
                </p>

                <ul>
                  <For each={form.startDates}>
                    {(startDate) => (
                      <li>
                        {format(startDate, 'LLLL yyyy', {
                          locale: LocaleFR,
                        })}
                      </li>
                    )}
                  </For>
                </ul>
              </>
            }
          >
            <p>
              Si vous confirmez cette action, votre abonnement sera renouvelé
              pour la période du {formatDate(form.startDates[0])} au{' '}
              {formatDate(add(form.startDates[0], { years: 1 }))}.
            </p>
          </Show>

          <p>Vous pouvez encore modifier la date de début d’abonnement :</p>
        </div>

        <form onSubmit={handleSubmit} class="mt-4 flex flex-col">
          <Show when={contract.periodicity === 'YEARLY'}>
            <Input
              class="mt-4"
              label="Date de début d'abonnement"
              type="date"
              name="startDate"
              required
              value={format(form.startDates[0], 'yyyy-MM-dd')}
              onChange={(e) => {
                setForm('startDates', [new Date(e.currentTarget.value)]);
              }}
              min={format(firstAvailableDate(), 'yyyy-MM-dd')}
              max={format(
                add(firstAvailableDate(), { years: 1 }),
                'yyyy-MM-dd',
              )}
            />
          </Show>

          <Show when={contract.periodicity === 'MONTHLY'}>
            <fieldset class="mt-4">
              <legend class="font-semibold">Mois de recharge</legend>

              <For each={availableStartDates()}>
                {(startDate) => (
                  <Checkbox
                    name="date"
                    class="text-sm uppercase"
                    // We want to disable the months that are already covered by a contract
                    disabled={subscription.contracts.some((contract) => {
                      const contractMonth = new Date(
                        contract.validityStartDate,
                      ).getMonth();
                      const startDateMonth = startDate.getMonth();

                      return contractMonth === startDateMonth;
                    })}
                    onChange={onChangeStartDates}
                    checked={form.startDates.some(
                      (date) => date.toISOString() === startDate.toISOString(),
                    )}
                    value={format(startDate, 'yyyy-MM-dd')}
                    label={format(startDate, 'LLLL yyyy', {
                      locale: LocaleFR,
                    })}
                  />
                )}
              </For>
            </fieldset>
          </Show>

          <Button type="submit" variant="secondary" class="mx-auto mt-8">
            Confirmer
          </Button>
        </form>
      </Modal>
    </section>
  );
}

export function SearchHeader(
  props: ParentProps<{
    isFull?: boolean;
    value?: { duration?: string; profile?: string; frequency?: string };
    onSearch: (params: URLSearchParams) => void;
  }>,
) {
  const [sdk] = useSdk();

  const searchValue = {
    duration: '',
    profile: '',
    frequency: '',
    ...(props.value || {}),
  };

  const [searchForm, setSearchFrom] = createStore(searchValue);
  const [criterias] = createResource(sdk.catalog.criterias);

  type Frequencies = ReturnType<
    typeof criterias
  >['duration'][number]['frequency'];
  type Profiles = Frequencies[number]['profile'];

  const frequencies = createMemo<Frequencies>(() => {
    if (!criterias()) return [];
    if (!searchForm.duration) return [];

    const duration = criterias().duration.find(
      (criteria) => criteria.key === searchForm.duration,
    );
    if (!duration) return [];

    return duration.frequency;
  });

  const profile = createMemo<Profiles>(() => {
    if (!criterias()) return [];
    if (!searchForm.frequency) return [];

    const frequency = frequencies().find(
      (frequency) => frequency.key === searchForm.frequency,
    );
    if (!frequency) return [];

    return frequency.profile;
  });

  const isLessThanFiveYears = () => {
    return searchForm.profile === 'LESS_5_YEARS';
  };

  const canSearch = () => {
    const isDurationFilled = searchForm.duration !== '';
    const isFrequencyFilled = searchForm.frequency !== '';
    const isProfileFilled = !profile().length || searchForm.frequency !== '';

    return (
      isDurationFilled &&
      isFrequencyFilled &&
      isProfileFilled &&
      isLessThanFiveYears()
    );
  };

  function handleSearch(event: Event) {
    event.preventDefault();
    const params = new URLSearchParams(searchForm);
    props.onSearch(params);
  }

  return (
    <>
      <section
        classList={{ 'lg:py-40 py-12': props.isFull, 'pt-28': !props.isFull }}
        class="relative flex flex-col items-center justify-center px-4"
      >
        <div
          classList={{ 'h-full': props.isFull, 'lg:h-52 h-72': !props.isFull }}
          class="absolute left-0 right-0 top-0 after:absolute after:inset-0 after:z-10 after:bg-black/20"
        >
          <img
            src={homeBg.src}
            height={homeBg.height}
            width={homeBg.width}
            alt="Bannière d'accueil de la eboutique Bordeaux"
            class="absolute z-0 h-full w-full object-cover object-bottom"
          />
        </div>

        <h2 class="z-20 block text-center text-xl font-semibold text-white lg:text-3xl">
          Trouver le titre de transport qu'il vous faut
        </h2>

        <form
          onSubmit={handleSearch}
          class="relative z-20 mx-auto mt-8 w-full max-w-4xl"
        >
          <div class="flex flex-col divide-y lg:flex-row lg:divide-x lg:divide-y-0">
            <select
              required
              name="duration"
              id="duration"
              class="safari-select-fix block flex-1 rounded-t-lg bg-white p-4 lg:rounded-l-full lg:p-6"
              value={searchForm.duration}
              onChange={(event) => {
                setSearchFrom('duration', event.currentTarget.value);
              }}
            >
              <option value="">Période de voyage</option>
              <For each={criterias()?.duration}>
                {(option) => <option value={option.key}>{option.value}</option>}
              </For>
            </select>

            <select
              required
              name="frequency"
              id="frequency"
              class="safari-select-fix block flex-1 rounded-none bg-white p-4 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:opacity-100 lg:p-6"
              value={searchForm.frequency}
              disabled={!frequencies()?.length}
              onChange={(event) => {
                setSearchFrom('frequency', event.currentTarget.value);
              }}
            >
              <option value="">Plus précisément</option>
              <For each={frequencies()}>
                {(option) => <option value={option.key}>{option.value}</option>}
              </For>
            </select>

            <select
              name="profile"
              id="profile"
              class="safari-select-fix block flex-1 rounded-b-lg bg-white p-4 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:opacity-100 lg:rounded-r-full lg:p-6"
              value={searchForm.profile}
              disabled={!profile()?.length}
              required={profile()?.length > 0}
              onChange={(event) => {
                setSearchFrom('profile', event.currentTarget.value);
              }}
            >
              <option value="">Age du voyageur</option>
              <For each={profile()}>
                {(option) => <option value={option.key}>{option.value}</option>}
              </For>
            </select>
          </div>

          <button
            type="submit"
            disabled={canSearch()}
            class="ml-auto mt-4 flex space-x-2 rounded-full bg-secondary px-4 py-3 text-white disabled:opacity-50 lg:absolute lg:right-1 lg:top-1/2 lg:mt-0 lg:-translate-y-1/2 lg:space-x-0 lg:px-5 lg:py-5"
          >
            <span class="lg:sr-only">Rechercher</span>
            <Icon path={search} class="h-6" />
          </button>
        </form>
      </section>

      <Show when={isLessThanFiveYears()}>
        <p class="z-20 mt-4 text-center">
          Bonne nouvelle ! Le réseau TBM est gratuit pour les enfants de moins
          de 5 ans. <br />
          Attention, en cas de contrôle, vous devez présenter un justificatif de
          date de naissance.
        </p>
      </Show>
    </>
  );
}

export default function Home() {
  const [sdk] = useSdk();
  const go = useNavigate();
  const [auth, authActions] = useAuth();
  const [globalStore, globalActions] = useGlobalStore();

  onMount(() => {
    dispatchGAPage('home', auth.user);
  });

  const [featured] = createResource(async () => {
    const featured = await sdk.catalog.featured();

    const sorted = featured.sort((productA, productB) => {
      return productA.sort - productB.sort;
    });

    const products = sorted.map((featuredProduct) => {
      for (const category of globalStore.catalog) {
        for (const product of category.products) {
          if (product.id === featuredProduct.productId) {
            return product;
          }
        }

        for (const child of category.children) {
          for (const product of child.products) {
            if (product.id === featuredProduct.productId) {
              return product;
            }
          }
        }
      }

      return featuredProduct as unknown as Product;
    });

    const dataGA = {
      ecommerce: {
        item_list_id: 'home',
        item_list_name: 'Home',
        items: products.map((product, index) => ({
          item_id: product.id,
          item_name: product.title,
          item_brand: formatCategoryNameForGA(product.categoryId),
          item_list_id: 'home',
          item_list_name: 'Home',
          price: formatPriceForGA(product.price),
          currency: 'EUR',
          quantity: '1',
          index: index + 1,
          item_category: formatCategoryNameForGA(product.categoryId),
        })),
      },
    };

    dispatchGAEvent('view_item_list', null, auth.user, dataGA);

    return featured
      .sort((productA, productB) => {
        return productA.sort - productB.sort;
      })
      .map((featuredProduct) => {
        for (const category of globalStore.catalog) {
          for (const product of category.products) {
            if (product.id === featuredProduct.productId) {
              return product;
            }
          }

          for (const child of category.children) {
            for (const product of child.products) {
              if (product.id === featuredProduct.productId) {
                return product;
              }
            }
          }
        }

        return featuredProduct as unknown as Product;
      });
  });

  const [notices] = createResource(async () => {
    const notices = await sdk.notices.list();

    const push1 = notices[1];
    const push2 = notices[2];
    const push3 = notices[3];
    const push4 = notices[4];

    return { push1, push2, push3, push4 };
  });

  const categoryTERLink = () => {
    const category = globalStore.categories.find((category) =>
      category.name.toLowerCase().includes('annuels tbm + train'),
    );

    if (!category) return;
    return '/categories/' + category.id;
  };

  const [subscriptonsExpires] = createResource(
    () => auth.isAuthenticated,
    (isAuthenticated) => {
      return isAuthenticated ? sdk.customer.subscriptionsExpires() : null;
    },
  );

  function handleSearch(params: URLSearchParams) {
    return go(`/search?${params.toString()}`);
  }

  return (
    <>
      <Title>Boutique en ligne TBM : achetez vos tickets et abonnements</Title>
      <SearchHeader isFull onSearch={handleSearch} />

      <Show when={subscriptonsExpires()?.length === 1} keyed>
        <ReSubscription subscription={subscriptonsExpires()[0]} />
      </Show>

      <Show when={subscriptonsExpires()?.length > 1}>
        <section class="px-4 py-12">
          <div class="mx-auto flex max-w-7xl flex-col">
            <PrettyTitle color="green">Se réabonner en un clic</PrettyTitle>

            <div class="relative mt-8 grid gap-6">
              <p class="text-center">
                Plusieurs de vos abonnements arrivent bientôt à expiration.
                N'attendez plus pour les renouveler !
              </p>
            </div>

            <Button
              href="/account/voyagers"
              variant="primary"
              class="mx-auto mt-8"
            >
              Gérer mes voyageurs
            </Button>
          </div>
        </section>
      </Show>

      <section class="px-4 py-12">
        <div class="mx-auto flex max-w-7xl flex-col">
          <PrettyTitle color="blue">Tous nos titres et tarifs</PrettyTitle>

          <div class="relative mt-8 grid gap-6 lg:grid-cols-3">
            <FancyCategory
              image={categorySingle}
              title="Tickets à l'unité"
              description="Vous voyagez occasionnellement sur le réseau TBM ? Découvrez nos titres à l'unité !"
              class="lg:col-start-1 lg:row-start-1"
              href="/categories/1"
            />
            <FancyCategory
              image={categoryJournee}
              title="Tickets à la journée"
              description="
              Circulez librement sur le réseau TBM pendant 24h, 48h et jusqu'à 7 jours."
              class="lg:col-start-1 lg:row-start-2"
              href="/categories/2"
            />
            <FancyCategory
              image={categoryAnnuel}
              title="Abonnements mensuels"
              description="Grâce aux abonnements mensuels voyagez en illimité et sans engagement sur le réseau TBM."
              class="lg:col-start-1 lg:row-start-3"
              href="/categories/3"
            />
            <FancyCategory
              image={categoryAnnuel}
              title="Abonnements annuels"
              description="Profitez du réseau TBM toute l'année et sans contraintes avec les abonnements annuels."
              class="lg:col-start-2 lg:row-start-1"
              href="/categories/4"
            />

            <FancyCategory
              image={categoryAnnuel}
              title="Abonnements annuels TBM + Trains"
              description="Profitez d'un abonnement illimité incluant l'accès au réseau TER Nouvelle-Aquitaine dans Bordeaux Métropole."
              class="lg:col-start-2 lg:row-start-2"
              href={categoryTERLink()}
            />

            <A
              href={notices()?.push2?.externalUrl ?? ''}
              class="relative hidden max-h-full overflow-hidden rounded-lg bg-white object-cover lg:col-start-2 lg:row-start-3 lg:block"
            >
              <img
                src={notices()?.push2?.rectanglePictureUrl}
                alt={notices()?.push2?.content}
                class="absolute left-0 top-1/2 w-full -translate-y-1/2"
                classList={{ hidden: !notices()?.push2?.rectanglePictureUrl }}
              />
            </A>
            <A
              href={notices()?.push3?.externalUrl ?? ''}
              class="relative hidden overflow-hidden rounded-lg bg-white object-cover lg:col-start-3 lg:row-span-3 lg:row-start-1 lg:block"
            >
              <img
                src={notices()?.push3?.totemPictureUrl}
                alt={notices()?.push3?.content}
                class="absolute left-0 top-1/2 min-h-full w-full -translate-y-1/2"
                classList={{ hidden: !notices()?.push3?.totemPictureUrl }}
              />
            </A>
          </div>
        </div>

        <div class="mt-8  max-w-full lg:hidden">
          <Carousel class="max-h-[400px]">
            <Show when={notices()?.push2?.mobilePictureUrl}>
              <CarouselSlide>
                <A
                  href={notices()?.push2?.externalUrl ?? ''}
                  class="relative flex h-full items-center justify-center overflow-hidden rounded-lg"
                >
                  <img
                    loading="lazy"
                    src={notices()?.push2?.mobilePictureUrl}
                    alt={notices()?.push2?.content}
                    class="block h-full w-full object-cover"
                  />
                </A>
              </CarouselSlide>
            </Show>

            <Show when={notices()?.push3?.mobilePictureUrl}>
              <CarouselSlide>
                <A
                  href={notices()?.push3?.externalUrl ?? ''}
                  class="relative flex h-full items-center justify-center overflow-hidden rounded-lg"
                >
                  <img
                    loading="lazy"
                    src={notices()?.push3?.mobilePictureUrl}
                    alt={notices()?.push3?.content}
                    class="block h-full w-full object-cover"
                  />
                </A>
              </CarouselSlide>
            </Show>
          </Carousel>
        </div>
      </section>

      <Show when={featured()?.length}>
        <section class="bg-white px-4 py-12">
          <div class="mx-auto max-w-7xl">
            <PrettyTitle color="pink">Titres du moment</PrettyTitle>

            <Carousel
              class="mt-8 min-h-[450px]"
              breakpoints={{
                '(min-width: 1024px)': {
                  slides: { perView: 3, spacing: 15 },
                },
                // @ts-ignore
                slides: { perView: 1 },
              }}
            >
              <For each={featured()}>
                {(product) => (
                  <CarouselSlide>
                    <FancyProduct {...product} />
                  </CarouselSlide>
                )}
              </For>
            </Carousel>

            <Show when={notices()?.push4?.externalUrl ?? ''}>
              <A
                href={notices()?.push4?.externalUrl ?? ''}
                class="mt-8 block overflow-hidden rounded-lg"
              >
                <span class="sr-only">{notices()?.push4?.content}</span>
                <picture class="w-full">
                  <source
                    srcset={notices()?.push4?.bannerPictureUrl ?? ''}
                    media="(min-width: 1024px)"
                  />
                  <source
                    srcset={notices()?.push4?.mobilePictureUrl ?? ''}
                    media="(max-width: 1023px)"
                  />
                  <img
                    srcset={notices()?.push4?.bannerPictureUrl ?? ''}
                    alt={notices()?.push4?.content}
                    class="w-full"
                    classList={{ hidden: !notices()?.push4?.bannerPictureUrl }}
                  />
                </picture>
              </A>
            </Show>
          </div>
        </section>
      </Show>

      {/* TODO: Enable when this shit smart context works */}
      <section class="px-4 py-12 ">
        <div class="mx-auto max-w-7xl lg:flex lg:justify-start lg:space-x-8">
          <div class="lg:w-2/3">
            <h3 class="flex items-center space-x-2 text-xl font-semibold">
              <img
                src={faq1}
                alt="Icone de questions fréquentes"
                height="36"
                width="36"
                class="block h-9 w-auto"
              />
              <span>Questions fréquentes</span>
            </h3>

            <SmartContextContainer tags={[TAGS.HOME]} />
          </div>

          <div class="mt-12 lg:mt-0 lg:w-1/3">
            <h3 class="flex items-center space-x-2 text-xl font-semibold">
              <img
                src={faq2}
                alt="Icone d'accès rapide"
                height="39"
                width="36"
                class="block h-9 w-auto"
              />
              <span>Accès rapides</span>
            </h3>

            <ul class="mt-4 flex flex-col space-y-4">
              <li>
                <A
                  href={auth.isAuthenticated ? '/account' : ''}
				  onClick={async () => {
					if (!auth.isAuthenticated) {
					  await authActions.login();
					}
				  }}
                  class="flex items-center justify-between rounded-lg bg-white p-4 font-semibold"
                >
                  <span>Mes infos personnelles</span>
                  <Icon
                    path={chevronRight}
                    class="h-6 transition-transform duration-300 group-open:rotate-180"
                  />
                </A>
              </li>
              <li>
                <A
                  href={auth.isAuthenticated ? '/account/history' : ''}
				  onClick={async () => {
					if (!auth.isAuthenticated) {
					  await authActions.login();
					}
				  }}
                  class="flex items-center justify-between rounded-lg bg-white p-4 font-semibold"
                >
                  <span>Mes commandes</span>
                  <Icon
                    path={chevronRight}
                    class="h-6 transition-transform duration-300 group-open:rotate-180"
                  />
                </A>
              </li>
              <li>
                <A
                  href={auth.isAuthenticated ? '/account/voyagers' : ''}
				  onClick={async () => {
					if (!auth.isAuthenticated) {
					  await authActions.login();
					}
				  }}
                  class="flex items-center justify-between rounded-lg bg-white p-4 font-semibold"
                >
                  <span>Mes voyageurs</span>
                  <Icon
                    path={chevronRight}
                    class="h-6 transition-transform duration-300 group-open:rotate-180"
                  />
                </A>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
