import { Discount, Product, ShopProfile, Child } from '@/lib/sdk';
import { defineStore } from '@/utils/defineStore';

export const [TunnelProvider, useTunnel] = defineStore({
  state: () => ({
    product: null as Product,
    options: null as Product[],
    profile: null as ShopProfile,
    quantity: 1,
    passenger: '',
	passengerRcuId: '',
	passengerInfos: null as Child,
    card: null,
    extra: null,
    organizationCode: '',
    needsAdditionalData: false,
    needsToOrderProfile: false,
    needsToValidateTemporalValidity: false,
    startDates: [] as string[],
    documents: {} as Record<string, string>,
    discount: null as Discount,
	delivery: null,
  }),
  storage: {
    key: 'tunnel',
  },
});
