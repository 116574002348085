import {Customer} from '@/lib/sdk';
import {defineStore} from '@/utils/defineStore';
import {useSdk} from '@/stores/sdk';


async function getWellKnownConfiguration(sdk) {
  const config = localStorage.getItem('eshop-bd-config');

  if (config) return JSON.parse(config);

  try {

	const fetchedConfig = await sdk.auth.getConfig();

	localStorage.setItem('eshop-bd-config', JSON.stringify(fetchedConfig));
	return fetchedConfig;
  } catch (error) {

	this.resetAuthStorage();

	return undefined;
  }
}

function randomString(size = 128): string {
  const randomValues = crypto.getRandomValues(new Uint32Array(size));
  return base64UrlArrayBufferEncode(randomValues);
}

function base64UrlArrayBufferEncode(arrayBuffer: ArrayBuffer) {
  const chars =
	  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  const bytes = new Uint8Array(arrayBuffer);
  const len = bytes.length;
  // eslint-disable-next-line fp/no-let
  let base64 = '';
  // eslint-disable-next-line fp/no-let
  let i: number;

  // Use a lookup table to find the index.
  const lookup = new Uint8Array(256);
  for (i = 0; i < chars.length; i++) {
	lookup[chars.charCodeAt(i)] = i;
  }

  for (i = 0; i < len; i += 3) {
	base64 += chars[bytes[i] >> 2];
	base64 += chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
	base64 += chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
	base64 += chars[bytes[i + 2] & 63];
  }

  if (len % 3 === 2) {
	base64 = base64.substring(0, base64.length - 1) + '=';
  } else if (len % 3 === 1) {
	base64 = base64.substring(0, base64.length - 2) + '==';
  }

  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

export const [AuthProvider, useAuth] = defineStore({
  state: () => {
	const token = localStorage.getItem('eshop-bd-authToken') as string;
	const customerString = localStorage.getItem('customer') as string;
	const user =
		token && customerString ? JSON.parse(customerString) : undefined;
	const tokenExpiration = localStorage.getItem('eshop-bd-authTokenExpires') as string;
	const [sdk] = useSdk();
	const refreshToken = localStorage.getItem('eshop-bd-refreshToken') as string;
	const idToken = localStorage.getItem('eshop-bd-idToken') as string;

	return {
	  token,
	  loading: false,
	  user: user,
	  sdk,
	  refreshToken,
	  idToken,
	  tokenExpiration,
	  get isAuthenticated(): boolean {
		return this.token && this.user;
	  },
	  get getUser(): Customer | undefined {
		return this.user;
	  },
	  get fullname(): string {
		const user: Customer = this.user;
		if (!user) return '';

		return `${user.firstName} ${user.lastName}`;
	  },
	  get isPaired(): boolean {
		const user = this.user as Customer;
		return Boolean(user?.thalesCustomerId);
	  },
	  get svdPairing(): boolean {
		return Boolean(this.user.technicalAccounts.some((account) => account.application === 'SVD'));
	  }
	};
  },
  actions: (state, setState) => ({
	resetAuthStorage() {
	  localStorage.removeItem('eshop-bd-authToken');
	  localStorage.removeItem('eshop-bd-authTokenExpires');
	  localStorage.removeItem('eshop-bd-idToken');
	  localStorage.removeItem('eshop-bd-refreshToken');
	  localStorage.removeItem('eshop-bd-refreshTokenExpires');
	  localStorage.removeItem('customer');
	  localStorage.removeItem('global');
	},
	async login(noPrompt = false) {
	  const config = await getWellKnownConfiguration(state.sdk);
	  if (!config) {
		return undefined;
	  }

	  const clientId = import.meta.env.VITE_AUTH_CLIENT_ID as string;
	  const scope = import.meta.env.VITE_CIAM_SCOPE as string

	  const verifier = getVerifier();
	  const challenge = await digestMessage(verifier);

	  const url = new URL(config.authorization_endpoint);
	  url.searchParams.set('client_id', clientId);
	  url.searchParams.set(
		  'redirect_uri',
		  new URL(location.origin + location.pathname).toString(),
	  );
	  url.searchParams.set('scope', scope);
	  url.searchParams.set('response_mode', 'query');
	  url.searchParams.set('code_challenge_method', 'S256');
	  url.searchParams.set('code_challenge', challenge);
	  url.searchParams.set('response_type', 'code');
	  url.searchParams.set('state', verifier);
	  url.searchParams.set('nonce', challenge);

	  if (noPrompt) {
		url.searchParams.set('prompt', 'none');
	  }

	  return (window.location.href = url.toString());
	},
	async getCurrentCustomer() {

	  try {
		await state.sdk.auth.verifyUser(state.token);
		const customer = await state.sdk.auth.customer(state.token);

		if (!customer) return;

		setState('user', customer);
		localStorage.setItem('customer', JSON.stringify(customer));

		return customer;

	  } catch (e) {
		await this.refresh();
		return;
	  }
	},

	async logout() {
	  const config = await getWellKnownConfiguration(state.sdk);
	  if (!config) return;

	  const idToken = localStorage.getItem('eshop-bd-idToken') as string;

	  if (!idToken) return;

	  this.resetAuthStorage()

	  const url = new URL(config.end_session_endpoint);
	  url.searchParams.set('id_token_hint', idToken);
	  url.searchParams.set(
		  'post_logout_redirect_uri',
		  new URL(location.origin).toString(),
	  );
	  return (window.location.href = url.toString());
	},
	async refresh() {

	  try {
		const refreshToken = localStorage.getItem(
			'eshop-bd-refreshToken',
		) as string || state.refreshToken

		const config = await getWellKnownConfiguration(state.sdk);


		const clientId = import.meta.env.VITE_AUTH_CLIENT_ID as string;

		const now = +Date.now();

		const data = await state.sdk.auth.refresh(config, refreshToken, clientId)

		const tokenExpiration = String(now + (data.expires_in * 1000));

		localStorage.setItem('eshop-bd-authToken', data.access_token);
		localStorage.setItem(
			'eshop-bd-authTokenExpires',
			tokenExpiration,
		);

		localStorage.setItem('eshop-bd-idToken', data.id_token);
		localStorage.setItem('eshop-bd-refreshToken', data.refresh_token);
		localStorage.setItem(
			'eshop-bd-refreshTokenExpires',
			`${now + data.refresh_expires_in * 1000}`,
		);

		setState('token', data.access_token);
		setState('refreshToken', data.refresh_token);
		setState('tokenExpiration', tokenExpiration);

		await this.getCurrentCustomer();

		return data;

	  } catch (error) {
		this.resetAuthStorage();
		setState('user', undefined);
	  }
	},

	async getAuthToken(code: string) {
	  try {
		const config = await getWellKnownConfiguration(state.sdk);

		const clientId = import.meta.env.VITE_AUTH_CLIENT_ID as string;

		const verifier = getVerifier();

		const now = +Date.now();

		const data = await state.sdk.auth.getAuthToken(config, code, verifier, clientId);

		localStorage.setItem('eshop-bd-authToken', data.access_token);

		const tokenExpiration = String(now + (data.expires_in * 1000));

		localStorage.setItem(
			'eshop-bd-authTokenExpires',
			tokenExpiration,
		);

		localStorage.setItem('eshop-bd-idToken', data.id_token);
		localStorage.setItem('eshop-bd-refreshToken', data.refresh_token);
		localStorage.setItem(
			'eshop-bd-refreshTokenExpires',
			`${now + data.refresh_expires_in * 1000}`,
		);
		localStorage.removeItem('eshop-bd-verifier');

		setState('token', data.access_token);
		setState('refreshToken', data.refresh_token);
		setState('tokenExpiration', tokenExpiration);

		await this.getCurrentCustomer();
	  } catch (error) {
		this.resetAuthStorage();
	  }
	},
	async checkLogged() {

	  const localStorageToken = localStorage.getItem('eshop-bd-authToken');

	  if (localStorageToken) {
		setState('token', localStorageToken);

		await this.getCurrentCustomer();

		return (this.loading = false);
	  }

	  const params = new URL(location.href).searchParams;
	  const code = params.get('code');
	  const error = params.get('error');

	  if (error && error === 'login_required') {
		history.replaceState(
			history.state,
			'',
			location.origin + location.pathname,
		);

		return false;
	  }

	  if (code) {
		history.replaceState(
			history.state,
			'',
			location.origin + location.pathname,
		);

		return await this.getAuthToken(code as string);
	  }

	  return await this.login(true);
	},
  })
});

function getVerifier() {
  const verifier = localStorage.getItem('eshop-bd-verifier') || randomString(32);

  localStorage.setItem('eshop-bd-verifier', verifier);

  return verifier;
}

async function digestMessage(message: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const digest = await window.crypto.subtle.digest('SHA-256', data);

  return base64UrlArrayBufferEncode(digest);
}